<template>
  <el-dialog
    v-bind="{title:'新增企业通告',width:'800px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :destroy-on-close="true"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="115px" :model="submitForm" :rules="rules">
      <el-form-item label="下发类型">
        <el-radio-group v-model="sendType">
          <el-radio :label="1">平台下发</el-radio>
          <el-radio :label="2">短信下发</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 选择发送区域 -->
      <el-row>
        <el-col :span="12">
          <el-form-item label="下发区域" >
            <!-- 为什么不能选有关联关系的，因为相关账号下的数据不全 -->
            <div style="height:280px;overflow-y:auto;border:1px solid #f5f5f5;border-radius: 3px;">
              <el-tree
                ref="areaTree"
                show-checkbox
                @check="onSeleced"
                :data="areaList"
                :props="{label:'name'}"
                node-key="id">
              </el-tree>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="下发企业" prop="compIdList" :rules="{required: true, message: '请选择下发企业'}">
            <el-select class="w-220" v-model.trim="submitForm.compIdList" multiple collapse-tags clearable placeholder="请先选择下发区域" filterable>
              <el-option v-for="item in validCompList" :key="item.compId" :value="item.compId" :label="item.compName"></el-option>
            </el-select>
            <i></i>
          </el-form-item>

          <div style="width: 320px; margin:0 auto; padding: 3px;border:1px solid #f5f5f5;border-radius: 3px;">
            <span class="fs-12 ml-4">共选中{{checkedComps.length}}条数据</span>
            <div style=" height:211px;overflow-y:auto;">
              <el-tag style="margin:3px;" v-for="item in checkedComps" :key="item.compId">{{item.compName}}</el-tag>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- 平台下发 -->
      <template v-if="sendType==1">
        <el-form-item label="公告标题" prop="title" key="title" :rules="{required: true, message: '请输入标题'}">
          <el-input class="w-350" v-model.trim="submitForm.title" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="公告附件" prop="acceFilURL" key="acceFilURL" :rules="{required: true, message: '请上传附件'}">
          <upload-pdf :src.sync="submitForm.acceFilURL" @uploaded="uploaded" />
        </el-form-item>
      </template>
      <template v-if="sendType==2">
        <el-form-item label="下发岗位" prop="seniPositList" key="seniPositList" :rules="{required: true, message: '请选择下发岗位'}">
          <el-select style="width:600px;" v-model.trim="submitForm.seniPositList" multiple placeholder="请选择下发岗位">
            <el-option v-for="item in stationList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <!-- 短信模板=》短信下发类型：月报，年报 -->
        <el-form-item label="短信模板" prop="messageModelId" key="messageModelId" :rules="{required: true, message: '请选择短信模板'}">
          <el-select class="w-350" v-model="submitForm.messageModelId">
            <el-option v-for="item in msgTmpls" :key="item.messageModelId" :value="item.messageModelId" :label="item.modelName" />
          </el-select>
          <el-popover
            v-if="submitForm.messageModelId"
            placement="top-start"
            width="350"
            trigger="hover">
            <div v-html="messageModelText"></div>
            <el-icon class="el-icon-question ml-5" slot="reference"></el-icon>
          </el-popover>
        </el-form-item>

        <el-form-item v-for="item in paramList" :key="item.key" :label="item.key">
          <el-input class="w-350" v-model="item.value" placeholder="请输入（短信模板参数）" />
        </el-form-item>
      </template>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getMessageModelList,
  getAreaInfoByAccount,
  getCompInfoByArea,
  addTopi,
  addTopiMessage
} from '@/apis/warningManage/msgManage'
import UploadPdf from '@/components/UploadPdf'
import XEUtils from 'xe-utils'

export default {
  props: {
    visible: { type: Boolean, default: false, require: true },
    editData: { type: Object }
  },
  computed: {
    visibleDialog: {
      set (newValue) { this.$emit('update:visible', newValue) },
      get () { return this.visible }
    },
    /**
         * 有效的企业列表：
         * 1. 假设为 浙江id为1, 杭州id为2，余杭id为3
         *    选择区域，浙江/杭州，ids: [1,2]
         *    那么企业中只要有 省id为1 市id为2 的就为有效企业, 例如：浙江/杭州: 1,2，浙江/杭州/余杭: 1,2,3；
         *
         * 2. 每次选择区域，会重置选中的企业ids 为 当前所有的有效企业的id
         */
    validCompList () {
      const submitForm = this.submitForm
      // 选中的区域
      const checkedIds = this.checkedArea
      const validCompList = []
      const defaultCheckedCompIds = []
      this.compList.forEach((item) => {
        const areaIds = [item.regiProvinId, item.regiCityId, item.regiCountrId]

        if (checkedIds.some(checkedArea => areaIds.includes(checkedArea))) {
          validCompList.push(item)
          defaultCheckedCompIds.push(item.compId)
        }
      })
      submitForm.compIdList = defaultCheckedCompIds
      return validCompList
    },
    paramList () {
      const messageModelId = this.submitForm.messageModelId
      const target = this.msgTmpls.find(item => item.messageModelId == messageModelId) || { paramList: [] }
      return target.paramList
    },
    checkedComps () {
      const compIdList = this.submitForm.compIdList
      return this.validCompList.filter(comp => compIdList.includes(comp.compId))
    },
    messageModelText () {
      const messageModelId = this.submitForm.messageModelId
      const target = this.msgTmpls.find(item => item.messageModelId == messageModelId) || { modelShowContent: '' }
      return `短信模板预览：<br><br>${target.modelShowContent}<br><br>注：短信模板参数会替换到发送的短信中。`
    }
  },
  data () {
    return {
      msgTmpls: [], // 短信模板
      compList: [], // 当前账号下的企业
      areaList: [], // 当前账号下的区域
      areaNames: [],
      checkedArea: [], // 选中的区域id
      sendType: 1, // 下发类型 提交时不传，1 平台下发，2 短信下发
      stationList: [ // 重要岗位
        { id: 1, name: '法人' },
        { id: 10, name: '总经理' },
        { id: 15, name: '安全副总经理' },
        { id: 20, name: '技术负责人' },
        { id: 25, name: '车队负责人' },
        { id: 30, name: '办公室主任' },
        { id: 35, name: '网络管理员' },
        { id: 40, name: '统计人员' }
      ],
      submitForm: {
        compIdList: [], // 企业id
        seniPositList: [1, 10, 15, 20, 25, 30, 35, 40], // 岗位id
        acceFilURL: '', // 平台下发-附件地址
        messageModelId: '', // 短信下发-模板id
        paramData: '', // 模板参数
        title: ''
      },
      rules: {
        roleType: { required: true, trigger: 'change', message: '请选择角色类型' },
        roleName: { required: true, trigger: 'change', message: '请输入角色名称' }
      },
      loading: false
    }
  },
  created () {
    this.initCompList()
    this.initAreaList()
    getMessageModelList().then((res) => {
      this.msgTmpls = res.data.messageModelList
    })
  },
  methods: {
    onSeleced () {
      const areaKeys = this.checkedArea = this.$refs.areaTree.getCheckedKeys()
      const names = []
      XEUtils.eachTree(this.areaList, (item, index, items, path, parent) => {
        if ((!parent && areaKeys.includes(item.id)) || (parent && !areaKeys.includes(parent.id) && areaKeys.includes(item.id))) {
          let temp = this.areaList
          const innerName = []
          path.forEach(key => {
            temp = temp[key]
            if (temp.name) innerName.push(temp.name)
          })
          names.push(innerName.join('/'))
        }
      })
      this.areaNames = names.join(',')
    },

    sendTypeChange (sendType) {
      if (sendType == 2) {
        this.submitForm.seniPositList = this.stationList.map(item => item.id)
      }
    },

    uploaded ({ nowRelatUrl }) {
      this.submitForm.acceFilURL = nowRelatUrl
    },

    submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true
          const api = this.sendType == 1 ? addTopi({
            acceFilURL: this.submitForm.acceFilURL,
            areaName: this.areaNames,
            compIdList: this.submitForm.compIdList,
            title: this.submitForm.title
          }) : addTopiMessage({
            areaName: this.areaNames,
            compIdList: this.submitForm.compIdList,
            seniPositList: this.submitForm.seniPositList,
            paramData: this.paramList.map(item => item.value).join(','),
            messageModelId: this.submitForm.messageModelId
          })
          const res = await api
          if (res.code == 200) {
            this.$message.success('操作成功！')
            this.$emit('refresh')
            this.visibleDialog = false
          } else {
            this.$message.error(res.msg)
          }
        } else {
          return false
        }
      })
    },
    handlerClose () {
      this.areaNames = []
      this.checkedArea = [] // 选中的区域id
      this.sendType = 1 // 下发类型 提交时不传，1 平台下发，2 短信下发
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    },
    // 初始化表单
    handlerOpen () {
      // const { editData } = this
      // if (!editData) return

      // Object.keys(submitForm).forEach((key) => {
      //     const value = editData[key]
      //     submitForm[key] = value
      // })
    },
    // 获取企业列表
    async initCompList () {
      const res = await getCompInfoByArea()
      if (res.code == 200) {
        this.compList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取区域列表
    async initAreaList () {
      const res = await getAreaInfoByAccount()
      if (res.code == 200) {
        this.areaList = res.data
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  components: {
    UploadPdf
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body{
    max-height: 68vh;
    overflow-y: auto;
}
::v-deep .el-select__tags-text {
    display: inline-block;
    vertical-align: middle;
    max-width: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
