import http from '@/utils/http'

// 区域列表
export function getAreaInfoByAccount (p) {
  return http.get('/material/topi/getAreaInfoByAccount', p)
}

// 企业列表
export function getCompInfoByArea (p) {
  return http.get('/material/topi/getCompInfoByArea', p)
}

// 获取短信模板列表
export function getMessageModelList (p) {
  return http.get('/material/message/getMessageModelList', p)
}

// 获取短信发送人员列表
export function getMessagePersList (p) {
  return http.get('/material/message/getMessagePersList', p)
}

// 短信发送记录列表
export function getMessageSendLogList (p) {
  return http.get('/material/message/getMessageSendLogList', p)
}

// 发送短信模板数据
export function postMessage (p) {
  return http.post('/material/message/postMessage', p)
}

// 下发企业通告
export function addTopi (p) {
  return http.post('/material/topi/addTopi', p)
}

// 下发短信
export function addTopiMessage (p) {
  return http.post('/material/topi/addTopiMessage', p)
}

// 企业通告列表
export function topiCompList (p) {
  return http.get('/material/topi/topiCompList', p)
}

// 企业通告列表
export function deleteTopi (p) {
  return http.post('/material/topi/deleteTopi', p)
}
