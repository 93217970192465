<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="下发类型:">
        <el-select v-model="searchForm.type" clearable placeholder="全部">
          <el-option :value="1" label="平台下发"></el-option>
          <el-option :value="2" label="短信下发"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="下发时间:">
        <el-date-picker
          v-model="sendDate"
          clearable
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="企业通告名称:">
        <el-input v-model.trim="searchForm.title" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="创建人:">
        <el-input v-model.trim="searchForm.createName" placeholder="请输入" clearable></el-input>
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="warningMsg"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData">
      <template #buttons>
        <el-button v-auth="'通知通告_企业通告_新增'" type="primary" icon="el-icon-plus" @click="editVisible=true">新 增</el-button>
      </template>
      <vxe-column type="seq" title="序号" width="80" align="center"></vxe-column>
      <vxe-column field="areaName" title="区域名称">
        <template v-slot="{row}">
          <div class="flex align-center justify-center w-100-p" style="flex-wrap: nowrap;" v-if="row.areaName">
            <el-tag class="text-1line " style="flex-shrink:1;">{{row.areaName.split(',').filter(item => Boolean(item))[0]}}</el-tag>
            <el-popover
              placement="top-start"
              trigger="click">
              <div class="flex justify-start" style="max-width:800px;">
                <el-tag style="margin: 3px;" v-for="item in row.areaName.split(',').filter(item => Boolean(item))" :key="item">{{item}}</el-tag>
              </div>
              <div slot="reference" class="pointer ml-5" title="点击看详情">
                共{{row.areaName.split(',').filter(item => Boolean(item)).length}}条
              </div>
            </el-popover>
          </div>
        </template>
      </vxe-column>
      <vxe-column field="compName" title="企业名称">
        <template v-slot="{row}">
          <div class="flex align-center justify-center w-100-p" style="flex-wrap: nowrap;" v-if="row.compName">
            <el-tag class="text-1line " style="flex-shrink:1;">{{row.compName.split(',').filter(item => Boolean(item))[0]}}</el-tag>
            <el-popover
              placement="top-start"
              trigger="click">
              <div class="flex justify-start" style="max-width:800px;">
                <el-tag style="margin: 3px;" v-for="item in row.compName.split(',').filter(item => Boolean(item))" :key="item">{{item}}</el-tag>
              </div>
              <div slot="reference" class="pointer ml-5" title="点击看详情">
                共{{row.compName.split(',').filter(item => Boolean(item)).length}}家
              </div>
            </el-popover>
          </div>
        </template>
      </vxe-column>
      <vxe-column field="createName" title="创建人"></vxe-column>
      <vxe-column field="issuDatTim" title="下发时间"></vxe-column>
      <vxe-column field="orgaName" title="发布机构"></vxe-column>
      <vxe-column field="seniPosit" title="岗位">
        <template v-slot="{row}">
          <div class="flex align-center justify-center w-100-p" style="flex-wrap: nowrap;" v-if="row.seniPosit">
            <el-tag class="text-1line " style="flex-shrink:1;">{{row.seniPosit.split(',').filter(item => Boolean(item))[0]}}</el-tag>
            <el-popover
              placement="top-start"
              trigger="click">
              <div class="flex justify-start"  style="max-width:800px;">
                <el-tag style="margin: 3px;" v-for="item in row.seniPosit.split(',').filter(item => Boolean(item))" :key="item">{{item}}</el-tag>
              </div>
              <div slot="reference" class="pointer ml-5" title="点击看详情">
                共{{row.seniPosit.split(',').filter(item => Boolean(item)).length}}条
              </div>
            </el-popover>
          </div>
        </template>
      </vxe-column>
      <vxe-column field="title" title="企业通告标题"></vxe-column>
      <vxe-column field="typeName" title="下发类型"></vxe-column>
      <vxe-column title="操作" min-width="120">
        <template #default={row}>
          <el-button v-auth="'通知通告_企业通告_查看附件'" v-if="row.acceFilURL" type="primary" @click="previewPdf(row)" title="下载附件" >查看附件</el-button>
          <el-button v-auth="'通知通告_企业通告_删除'" type="danger" @click="handlerDelete(row.topiId)" title="删除" >删除</el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <!-- 新建|编辑 -->
    <send-msg :visible.sync="editVisible" :edit-data.sync="editData" @refresh="initList" />
  </PageContainer>
</template>

<script>
import { topiCompList, deleteTopi } from '@/apis/warningManage/msgManage'
import SendMsg from './components/SendMsg'
export default {
  data () {
    return {
      searchForm: {
        createName: '', // 创建人
        title: '', // 企业通告标题
        startDateTime: '', // 开始时间
        endDateTime: '', // 结束时间
        type: '' // 下发类型
      },
      // 表格相关
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 50,
        totalSize: 0
      },
      loading: false,
      editData: null,
      editVisible: false
    }
  },
  computed: {
    sendDate: {
      get () {
        const searchForm = this.searchForm
        if (searchForm.startDateTime && searchForm.endDateTime) {
          const [startDate] = searchForm.startDateTime.split(' ')
          const [endDate] = searchForm.endDateTime.split(' ')
          return [startDate, endDate]
        } else {
          return null
        }
      },
      set (newVal) {
        const searchForm = this.searchForm
        if (newVal) {
          const [startDate, endDate] = newVal
          searchForm.startDateTime = startDate + ' 00:00:00'
          searchForm.endDateTime = endDate + ' 23:59:59'
        } else {
          searchForm.startDateTime = ''
          searchForm.endDateTime = ''
        }
      }
    }
  },
  created () {
    this.initList()
  },
  methods: {
    previewPdf ({ acceFilURL }) {
      if (acceFilURL) {
        // getMediaUrl 是全局mixin
        window.open(this.getMediaUrl(acceFilURL), '_blank')
      } else {
        this.$message.error('没有可以预览的附件')
      }
    },
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },
    initList () {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      // Object.keys(params)
      topiCompList(params).then((res) => {
        this.loading = false
        if (res.code === 200) {
          this.tablePage.totalSize = res.data.totalSize
          this.tableData = res.data.rows.map((item) => {
            item.typeName = item.type === 1 ? '平台下发' : '短信下发'
            return item
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },

    goEditPerm (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.editVisible = true
    },

    handlerDelete (id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = { id }
        deleteTopi(params).then((res) => {
          if (res.code) {
            this.initList()
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }
  },
  components: {
    SendMsg
  }
}
</script>
